import { useEffect } from "react";

const useOnWindowResize = (onResize: () => void): void => {
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);
};

export default useOnWindowResize;
