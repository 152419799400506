import { DEPRECATED_ICONS } from "@src/deprecatedDesignSystem/deprecatedIcons";
import {
  DeprecatedColor,
  DeprecateTone,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { CSSProperties, FC, MouseEvent } from "react";
import { HOVER_TRANSITION } from "../styles/transitions";

export type DeprecatedIconType = keyof typeof DEPRECATED_ICONS;

export type DeprecatedIconProps = {
  type: DeprecatedIconType;
  iconSize?: number;
  containerSize?: number;
  color?: DeprecateTone | DeprecatedColor;
  strokeWidth?: number;
  style?: CSSProperties;
  svgStyle?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  svgStyleDeclaration?: StyleDeclaration;
  onClick?: (e: MouseEvent) => void;
};

const DeprecatedIcon: FC<DeprecatedIconProps> = ({
  type,
  iconSize = 20,
  containerSize = 24,
  color = deprecatedTones.gray12,
  strokeWidth = 1.5,
  style,
  svgStyle,
  styleDeclaration,
  svgStyleDeclaration,
  onClick,
}) => {
  const DynamicIcon = DEPRECATED_ICONS[type];
  return (
    <span
      className={css(
        styles.container,
        onClick && styles.clickable,
        styleDeclaration,
      )}
      style={{
        height: `${containerSize}px`,
        width: `${containerSize}px`,
        fontSize: `${iconSize}px`,
        color,
        ...style,
      }}
      onClick={onClick}
    >
      {DynamicIcon && (
        // @ts-ignore
        <DynamicIcon
          strokeWidth={strokeWidth}
          className={css(svgStyleDeclaration)}
          style={{ display: "block", ...svgStyle }}
        />
      )}
    </span>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: HOVER_TRANSITION,
  },
  clickable: {
    cursor: "pointer",
  },
  rotate: {
    transform: "rotate(180deg)",
  },
});

export default DeprecatedIcon;
