import DelayedSkeleton from "@components/ui/DelayedSkeleton";
import {
  DeprecatedColor,
  DeprecateTone,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useOnWindowResize from "@hooks/useOnWindowResize";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import React, {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  CSSProperties,
} from "react";
import { Route } from "nextjs-routes";
import useHover from "@hooks/useHover";
import { HOVER_TRANSITION } from "@src/deprecatedDesignSystem/styles/transitions";
import { cn } from "@src/ui/lib/utils";
import { cva } from "class-variance-authority";

export type TextType =
  | "title"
  | "H1"
  | "H2"
  | "H3"
  | "H4"
  | "P1"
  | "P2"
  | "P3"
  | "P4"
  | "training-content";
export type TextFontWeight = "Regular" | "Medium" | "SemiBold" | "Bold";

export const textVariants = cva("", {
  variants: {
    variant: {
      title: "leading-[42px] text-[42px] font-[PolySans] font-semibold",
      H1: "leading-[24px] text-[24px] tracking-[-0.019em] font-semibold",
      H2: "leading-[28px] text-[20px] tracking-[-0.017em] font-semibold",
      H3: "leading-[25px] text-[18px] tracking-[-0.014em] font-semibold",
      H4: "leading-[22px] text-[18px] tracking-[-0.014em] font-semibold",
      P1: "leading-[22px] text-[16px] tracking-[-0.01em] font-normal",
      P2: "leading-[20px] text-[14px] tracking-[-0.006em] font-normal",
      P3: "leading-[17px] text-[12px] tracking-0 font-normal",
      P4: "leading-[10px] text-[10px] tracking-0 font-normal",
      ["training-content"]:
        "leading-[125%] text-[20px] tracking-[-.035em] font-normal",
    },
  },
  defaultVariants: {
    variant: "P1",
  },
});

export type TextProps = ComponentProps<"div"> & {
  type: TextType;
  children: React.ReactNode;
  fontWeight?: TextFontWeight;
  multiline?: boolean;
  ellipsis?: boolean;
  ellipsisParentFlexDirection?: "row" | "col";
  disableEllipsisTooltip?: boolean;
  inline?: boolean;
  color?: DeprecateTone | DeprecatedColor;
  headlineFont?: boolean;
  styleDeclaration?: StyleDeclaration;
  style?: CSSProperties;
  loading?: boolean;
  skeletonWidth?: number;
  marginTop?: CSSProperties["marginTop"];
  marginBottom?: CSSProperties["marginBottom"];
  marginLeft?: CSSProperties["marginLeft"];
  marginRight?: CSSProperties["marginRight"];
  link?: Route | null | undefined;
  externalUrl?: string;
  linkOpenInNewTab?: boolean;
  hoverStyle?: CSSProperties;
};

const Text: FC<TextProps> = (props) => {
  const { link, externalUrl, linkOpenInNewTab, ...rest } = props;
  if (link || externalUrl) {
    const defaultHoverStyle: CSSProperties = {
      textDecoration: "underline",
    };
    return (
      <AutoLayout
        link={link}
        externalUrl={externalUrl}
        linkOpenInNewTab={linkOpenInNewTab}
        style={{ ...props.style }}
      >
        <TextInner
          {...rest}
          hoverStyle={{ ...defaultHoverStyle, ...props.hoverStyle }}
        />
      </AutoLayout>
    );
  } else {
    return <TextInner {...rest} />;
  }
};

const TextInner: FC<
  Omit<TextProps, "link" | "externalUrl" | "linkOpenInNewTab">
> = ({
  type,
  children,
  fontWeight = "Regular",
  color,
  multiline,
  ellipsis,
  ellipsisParentFlexDirection,
  inline,
  headlineFont = false,
  styleDeclaration,
  style,
  disableEllipsisTooltip,
  loading,
  skeletonWidth = 100,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  hoverStyle,
  ...props
}) => {
  if (ellipsis && multiline) {
    console.warn("Using ellipsis and multiline does not make sense.");
  }

  const margin = { marginTop, marginBottom, marginLeft, marginRight };
  const textTypeStyles: string = useMemo(
    () =>
      css(
        type === "title" && TextStyleDeclaration.title,
        type === "H1" && TextStyleDeclaration.h1,
        type === "H2" && TextStyleDeclaration.h2,
        type === "H3" && TextStyleDeclaration.h3,
        type === "H4" && TextStyleDeclaration.h4,
        type === "P1" && TextStyleDeclaration.p1,
        type === "P2" && TextStyleDeclaration.p2,
        type === "P3" && TextStyleDeclaration.p3,
        type === "P4" && TextStyleDeclaration.p4,
        fontWeight === "Regular" && TextStyleDeclaration.regular,
        fontWeight === "Medium" && TextStyleDeclaration.medium,
        fontWeight === "SemiBold" && TextStyleDeclaration.semibold,
        fontWeight === "Bold" && TextStyleDeclaration.bold,
        inline && TextStyleDeclaration.inline,
        headlineFont && TextStyleDeclaration.headlineFont,
        props.onClick != null && TextStyleDeclaration.hoverStyle,
        styleDeclaration,
      ),
    [fontWeight, headlineFont, inline, styleDeclaration, type, props.onClick],
  );
  const [isHovered1, hoverBind1] = useHover();
  const [isHovered2, hoverBind2] = useHover();
  const [isHovered3, hoverBind3] = useHover();
  if (loading && !children) {
    return (
      <AutoLayout
        {...hoverBind1}
        alignmentVertical={"center"}
        style={{
          transition: HOVER_TRANSITION,
          height: textTypeToLineHeight[type],
          ...(isHovered1 ? hoverStyle : {}),
        }}
      >
        <DelayedSkeleton
          height={textTypeToFontSize[type]}
          width={skeletonWidth}
        />
      </AutoLayout>
    );
  } else if (multiline) {
    return (
      <div
        {...hoverBind2}
        {...props}
        style={{
          color: color ? color : undefined,
          transition: HOVER_TRANSITION,
          lineHeight: textTypeToLineHeight[type],
          ...margin,
          ...(isHovered2 ? hoverStyle : {}),
          wordBreak: "break-word",
          ...style,
        }}
        className={cn(props.className, textTypeStyles)}
      >
        {children}
      </div>
    );
  } else if (ellipsis) {
    return (
      <EllipsisText
        {...props}
        type={type}
        style={{
          color: color ? color : undefined,
          transition: HOVER_TRANSITION,
          ...margin,
          ...style,
        }}
        className={cn(props.className, textTypeStyles)}
        parentFlexDirection={ellipsisParentFlexDirection || "col"}
        disableEllipsisTooltip={disableEllipsisTooltip}
        hoverStyle={hoverStyle}
      >
        {children}
      </EllipsisText>
    );
  } else {
    return (
      <span
        {...hoverBind3}
        {...props}
        style={{
          color: color ? color : undefined,
          transition: HOVER_TRANSITION,
          whiteSpace: "nowrap",
          ...margin,
          ...(isHovered3 ? hoverStyle : {}),
          ...style,
        }}
        className={cn(props.className, textTypeStyles)}
      >
        {children === "" || children === null || children === undefined
          ? " "
          : children}
      </span>
    );
  }
};

type EllipsisTextProps = ComponentProps<"div"> & {
  style?: CSSProperties;
  color?: DeprecateTone | DeprecatedColor;
  type: TextType;
  children: React.ReactNode;
  className?: string;
  parentFlexDirection: "row" | "col";
  disableEllipsisTooltip?: boolean;
  hoverStyle?: CSSProperties;
};

const EllipsisText: FC<EllipsisTextProps> = ({
  className,
  style,
  color,
  type,
  children,
  parentFlexDirection,
  disableEllipsisTooltip,
  hoverStyle,
  ...props
}) => {
  const ref = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const setShowTooltipWrapper = useCallback(() => {
    setShowTooltip(
      // @ts-ignore
      (ref?.current?.offsetWidth || 0) < (ref?.current?.scrollWidth || 0),
    );
  }, [setShowTooltip]);
  useEffect(() => {
    setTimeout(setShowTooltipWrapper, 0);
  });
  useOnWindowResize(setShowTooltipWrapper);
  const [isHovered, hoverBind] = useHover();

  return (
    <RadixTooltip.Root delayDuration={500}>
      <RadixTooltip.Trigger asChild>
        <div
          {...hoverBind}
          {...props}
          style={{
            color,
            transition: HOVER_TRANSITION,
            position: "relative",
            flex: parentFlexDirection === "row" ? 1 : undefined,
            alignSelf: parentFlexDirection === "col" ? "stretch" : undefined,
            minWidth: 50,
            height: textTypeToLineHeight[type],
            maxHeight: textTypeToLineHeight[type],
            minHeight: textTypeToLineHeight[type],
            ...style,
          }}
        >
          <div
            ref={ref}
            className={className}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              verticalAlign: "center",
              ...style,
              ...(isHovered ? hoverStyle : {}),
            }}
          >
            {children}
          </div>
        </div>
      </RadixTooltip.Trigger>
      {!disableEllipsisTooltip && (
        <RadixTooltip.Portal>
          <RadixTooltip.Content align={"start"} style={{ zIndex: 500 }}>
            {showTooltip && (
              <div
                style={{
                  backgroundColor: "white",
                  paddingLeft: 4,
                  paddingRight: 4,
                  borderRadius: 4,
                  border: "1px solid",
                  borderColor: deprecatedColors.border,
                }}
              >
                <Text type={"P3"} color={deprecatedTones.gray10}>
                  {children}
                </Text>
              </div>
            )}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      )}
    </RadixTooltip.Root>
  );
};

const textTypeToLineHeight: Record<TextType, CSSProperties["lineHeight"]> = {
  title: "42px",
  H1: "34px",
  H2: "28px",
  H3: "25px",
  H4: "22px",
  P1: "22px",
  P2: "20px",
  P3: "17px",
  P4: "10px",
  ["training-content"]: "125%",
};
const textTypeToFontSize: Record<TextType, number> = {
  title: 42,
  H1: 24,
  H2: 20,
  H3: 18,
  H4: 18,
  P1: 16,
  P2: 14,
  P3: 12,
  P4: 10,
  ["training-content"]: 20,
};

export const TextStyleDeclaration = StyleSheet.create({
  title: {
    fontSize: textTypeToFontSize.title,
    lineHeight: textTypeToLineHeight.title,
    fontWeight: 600,
    fontFamily: "PolySans",
  },
  h1: {
    fontSize: textTypeToFontSize.H1,
    letterSpacing: "-0.019em",
    lineHeight: textTypeToLineHeight.H1,
  },
  h2: {
    fontSize: textTypeToFontSize.H2,
    letterSpacing: "-0.017em",
    lineHeight: textTypeToLineHeight.H2,
  },
  h3: {
    fontSize: textTypeToFontSize.H3,
    letterSpacing: "-0.014em",
    lineHeight: textTypeToLineHeight.H3,
  },
  h4: {
    fontSize: textTypeToFontSize.H4,
    letterSpacing: "-0.014em",
    lineHeight: textTypeToLineHeight.H4,
  },
  p1: {
    fontSize: textTypeToFontSize.P1,
    letterSpacing: "-0.01em",
    lineHeight: textTypeToLineHeight.P1,
  },
  p2: {
    fontSize: textTypeToFontSize.P2,
    letterSpacing: "-0.006em",
    lineHeight: textTypeToLineHeight.P2,
  },
  p3: {
    fontSize: textTypeToFontSize.P3,
    letterSpacing: 0,
    lineHeight: textTypeToLineHeight.P3,
  },
  p4: {
    fontSize: textTypeToFontSize.P4,
    letterSpacing: 0,
    lineHeight: textTypeToLineHeight.P4,
  },
  regular: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
  semibold: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },
  inline: {
    display: "inline-block",
  },
  headlineFont: {
    fontFamily: "PolySans",
  },
  hoverStyle: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

export default Text;
