import Location from "./icons/location.svg";
import Option from "./icons/option.svg";
import DotsVerticalSix from "./icons/dots-vertical-six.svg";
import EmailPlus from "./icons/email-plus.svg";
import Party from "./icons/party.svg";
import ArrowUpRight from "./icons/arrow-up-right.svg";
import Grid from "./icons/grid.svg";
import Triangle from "./icons/triangle.svg";
import Search from "./icons/search.svg";
import Checkmark from "./icons/checkmark.svg";
import Sparkles2 from "./icons/sparkles-2.svg";
import ArrowDown from "./icons/arrow-down.svg";
import DotsHorizontal from "./icons/dots-horizontal.svg";
import Dots from "./icons/dots.svg";
import UnorderedList from "./icons/unordered-list.svg";
import Minimize2 from "./icons/minimize-2.svg";
import Information from "./icons/information.svg";
import Repeat from "./icons/repeat.svg";
import PencilBook from "./icons/pencil-book.svg";
import Chart from "./icons/chart.svg";
import Volcano from "./icons/volcano.svg";
import BellRinging from "./icons/bell-ringing.svg";
import Umbrella from "./icons/umbrella.svg";
import User from "./icons/user.svg";
import Fullscreen from "./icons/fullscreen.svg";
import IdBadge from "./icons/id-badge.svg";
import EmailError from "./icons/email-error.svg";
import Home from "./icons/home.svg";
import Alert from "./icons/alert.svg";
import ChevronDown from "./icons/chevron-down.svg";
import Rotate from "./icons/rotate.svg";
import FileText2 from "./icons/file-text-2.svg";
import Crop from "./icons/crop.svg";
import Tag from "./icons/tag.svg";
import Briefcase from "./icons/briefcase.svg";
import Microphone from "./icons/microphone.svg";
import File from "./icons/file.svg";
import Cabinet from "./icons/cabinet.svg";
import Hubspot from "./icons/hubspot.svg";
import DottedCircleLeft from "./icons/dotted-circle-left.svg";
import ShieldTick from "./icons/shield-tick.svg";
import Sparkles from "./icons/sparkles.svg";
import ChevronUp from "./icons/chevron-up.svg";
import Screw from "./icons/screw.svg";
import FileText from "./icons/file-text.svg";
import QrCode from "./icons/qr-code.svg";
import Languages from "./icons/languages.svg";
import Lock from "./icons/lock.svg";
import Toys from "./icons/toys.svg";
import Pen from "./icons/pen.svg";
import ChevronRight from "./icons/chevron-right.svg";
import Clipboard from "./icons/clipboard.svg";
import Link from "./icons/link.svg";
import Check2 from "./icons/check-2.svg";
import ArrowRight from "./icons/arrow-right.svg";
import LogOut from "./icons/log-out.svg";
import Settings from "./icons/settings.svg";
import Archive from "./icons/archive.svg";
import CloseCircle from "./icons/close-circle.svg";
import Mail from "./icons/mail.svg";
import Download from "./icons/download.svg";
import Pin from "./icons/pin.svg";
import SquareCheck from "./icons/square-check.svg";
import TextCapitalise from "./icons/text-capitalise.svg";
import BookOpen from "./icons/book-open.svg";
import UsersCircle from "./icons/users-circle.svg";
import Bolt from "./icons/bolt.svg";
import UserPlus from "./icons/user-plus.svg";
import Book from "./icons/book.svg";
import UserMinus from "./icons/user-minus.svg";
import Bell from "./icons/bell.svg";
import GitBranch from "./icons/git-branch.svg";
import Settings2 from "./icons/settings-2.svg";
import Lines from "./icons/lines.svg";
import Rotate2 from "./icons/rotate-2.svg";
import Bold from "./icons/bold.svg";
import Plus from "./icons/plus.svg";
import Paintbrush2 from "./icons/paintbrush-2.svg";
import Check from "./icons/check.svg";
import WriteNote from "./icons/write-note.svg";
import PieChart from "./icons/pie-chart.svg";
import Filter2 from "./icons/filter-2.svg";
import Grid3 from "./icons/grid-3.svg";
import Close from "./icons/close.svg";
import People from "./icons/people.svg";
import Paintbucket3 from "./icons/paintbucket-3.svg";
import FolderPlus from "./icons/folder-plus.svg";
import GitMerge from "./icons/git-merge.svg";
import Copy from "./icons/copy.svg";
import Coins from "./icons/coins.svg";
import Refresh from "./icons/refresh.svg";
import Text from "./icons/text.svg";
import Maximize2 from "./icons/maximize-2.svg";
import CheckCircle from "./icons/check-circle.svg";
import Badge from "./icons/badge.svg";
import RegisteredTrademark from "./icons/registered-trademark.svg";
import Message from "./icons/message.svg";
import ThumbsUp from "./icons/thumbs-up.svg";
import FaceSmile from "./icons/face-smile.svg";
import Play from "./icons/play.svg";
import BlindsClosed from "./icons/blinds-closed.svg";
import Scroll from "./icons/scroll.svg";
import Redo from "./icons/redo.svg";
import GlobeWithPin from "./icons/globe-with-pin.svg";
import Trash from "./icons/trash.svg";
import PhotoPlus from "./icons/photo-plus.svg";
import TextAlignLeft from "./icons/text-align-left.svg";
import Star from "./icons/star.svg";
import StarFilled from "./icons/star-filled.svg";
import Dollar2 from "./icons/dollar-2.svg";
import OrderedList from "./icons/ordered-list.svg";
import Edit from "./icons/edit.svg";
import LinkBreak from "./icons/link-break.svg";
import UploadCloud from "./icons/upload-cloud.svg";
import Spinner from "./icons/spinner.svg";
import Pdf from "./icons/pdf.svg";
import PlusCircle from "./icons/plus-circle.svg";
import CreditCard from "./icons/credit-card.svg";
import UserCircle from "./icons/user-circle.svg";
import Pencil from "./icons/pencil.svg";
import WifiNoConnection from "./icons/wifi-no-connection.svg";
import Language from "./icons/language.svg";
import Trash2 from "./icons/trash-2.svg";
import FilePlus from "./icons/file-plus.svg";
import Linked from "./icons/linked.svg";
import Clock from "./icons/clock.svg";
import Phone from "./icons/phone.svg";
import Eye from "./icons/eye.svg";
import LightBulb from "./icons/light-bulb.svg";
import Pins from "./icons/pins.svg";
import SquarePlus from "./icons/square-plus.svg";
import Alien2 from "./icons/alien-2.svg";
import ArrowUp from "./icons/arrow-up.svg";
import Money from "./icons/money.svg";
import SpeechBubble from "./icons/speech-bubble.svg";
import Undo from "./icons/undo.svg";
import BuildingStore from "./icons/building-store.svg";
import Robot from "./icons/robot.svg";
import Filter from "./icons/filter.svg";
import Italic from "./icons/italic.svg";
import Calendar from "./icons/calendar.svg";
import VideoCamera from "./icons/video-camera.svg";
import ArrowLeft from "./icons/arrow-left.svg";
import Paintbrush from "./icons/paintbrush.svg";
import MinusCircle from "./icons/minus-circle.svg";
import SpeakerVolumeHigh from "./icons/speaker-volume-high.svg";
import Upload from "./icons/upload.svg";
import Pause from "./icons/pause.svg";
import Bookmark from "./icons/bookmark.svg";
import Asterisk from "./icons/asterisk.svg";
import Iphone from "./icons/iphone.svg";
import WarningTriangle from "./icons/warning-triangle.svg";
import Refresh3 from "./icons/refresh-3.svg";
import DoubleChatBubble from "./icons/double-chat-bubble.svg";
import Cards from "./icons/cards.svg";
import Users from "./icons/users.svg";
import ColorSwatch from "./icons/color-swatch.svg";
import Timer from "./icons/timer.svg";
import SquareCross from "./icons/square-cross.svg";
import Minus from "./icons/minus.svg";
import HelpCircle from "./icons/help-circle.svg";
import Photo from "./icons/photo.svg";
import ChevronLeft from "./icons/chevron-left.svg";
import Print from "./icons/print.svg";
import FolderPerson from "./icons/folder-person.svg";
import DotsVertical from "./icons/dots-vertical.svg";
import ChartIncrease from "./icons/chart-increase.svg";
import AlignLeft from "./icons/align-left.svg";
import ClipboardCheck from "./icons/clipboard-check.svg";
import Cog from "./icons/cog.svg";
import EyeOff from "./icons/eye-off.svg";
import PencilRuler from "./icons/pencil-ruler.svg";
import QuestionMarkCircle from "./icons/question-mark-circle.svg";
import BellOff from "./icons/bell-off.svg";

export const DEPRECATED_ICONS = {
  grid: Grid,
  "bell-off": BellOff,
  "question-mark-circle": QuestionMarkCircle,
  "eye-off": EyeOff,
  triangle: Triangle,
  search: Search,
  "dots-vertical-six": DotsVerticalSix,
  "email-plus": EmailPlus,
  checkmark: Checkmark,
  "sparkles-2": Sparkles2,
  "arrow-down": ArrowDown,
  "arrow-up-right": ArrowUpRight,
  "dots-horizontal": DotsHorizontal,
  dots: Dots,
  "unordered-list": UnorderedList,
  "minimize-2": Minimize2,
  information: Information,
  repeat: Repeat,
  "pencil-book": PencilBook,
  chart: Chart,
  volcano: Volcano,
  "bell-ringing": BellRinging,
  umbrella: Umbrella,
  user: User,
  fullscreen: Fullscreen,
  "id-badge": IdBadge,
  "email-error": EmailError,
  party: Party,
  home: Home,
  alert: Alert,
  "chevron-down": ChevronDown,
  rotate: Rotate,
  "file-text-2": FileText2,
  crop: Crop,
  tag: Tag,
  option: Option,
  briefcase: Briefcase,
  map: Map,
  microphone: Microphone,
  file: File,
  cabinet: Cabinet,
  hubspot: Hubspot,
  "dotted-circle-left": DottedCircleLeft,
  "shield-tick": ShieldTick,
  sparkles: Sparkles,
  "chevron-up": ChevronUp,
  screw: Screw,
  "file-text": FileText,
  "qr-code": QrCode,
  languages: Languages,
  lock: Lock,
  toys: Toys,
  pen: Pen,
  "chevron-right": ChevronRight,
  clipboard: Clipboard,
  link: Link,
  "check-2": Check2,
  "arrow-right": ArrowRight,
  "log-out": LogOut,
  settings: Settings,
  archive: Archive,
  "close-circle": CloseCircle,
  mail: Mail,
  download: Download,
  pin: Pin,
  "square-check": SquareCheck,
  "text-capitalise": TextCapitalise,
  "book-open": BookOpen,
  "users-circle": UsersCircle,
  bolt: Bolt,
  "user-plus": UserPlus,
  book: Book,
  "user-minus": UserMinus,
  bell: Bell,
  "git-branch": GitBranch,
  "settings-2": Settings2,
  lines: Lines,
  "rotate-2": Rotate2,
  bold: Bold,
  plus: Plus,
  "paintbrush-2": Paintbrush2,
  check: Check,
  "write-note": WriteNote,
  "pie-chart": PieChart,
  "filter-2": Filter2,
  "grid-3": Grid3,
  close: Close,
  people: People,
  "paintbucket-3": Paintbucket3,
  "folder-plus": FolderPlus,
  "git-merge": GitMerge,
  copy: Copy,
  coins: Coins,
  refresh: Refresh,
  text: Text,
  "maximize-2": Maximize2,
  "check-circle": CheckCircle,
  badge: Badge,
  "registered-trademark": RegisteredTrademark,
  message: Message,
  "thumbs-up": ThumbsUp,
  "face-smile": FaceSmile,
  play: Play,
  "blinds-closed": BlindsClosed,
  scroll: Scroll,
  redo: Redo,
  "globe-with-pin": GlobeWithPin,
  trash: Trash,
  "photo-plus": PhotoPlus,
  "text-align-left": TextAlignLeft,
  star: Star,
  "star-filled": StarFilled,
  "dollar-2": Dollar2,
  "ordered-list": OrderedList,
  edit: Edit,
  "link-break": LinkBreak,
  "upload-cloud": UploadCloud,
  spinner: Spinner,
  pdf: Pdf,
  "plus-circle": PlusCircle,
  "credit-card": CreditCard,
  "user-circle": UserCircle,
  pencil: Pencil,
  "wifi-no-connection": WifiNoConnection,
  language: Language,
  "trash-2": Trash2,
  "file-plus": FilePlus,
  linked: Linked,
  clock: Clock,
  phone: Phone,
  eye: Eye,
  "light-bulb": LightBulb,
  location: Location,
  pins: Pins,
  "square-plus": SquarePlus,
  "alien-2": Alien2,
  "arrow-up": ArrowUp,
  money: Money,
  "speech-bubble": SpeechBubble,
  undo: Undo,
  "building-store": BuildingStore,
  robot: Robot,
  filter: Filter,
  italic: Italic,
  calendar: Calendar,
  "video-camera": VideoCamera,
  "arrow-left": ArrowLeft,
  paintbrush: Paintbrush,
  "minus-circle": MinusCircle,
  "speaker-volume-high": SpeakerVolumeHigh,
  upload: Upload,
  pause: Pause,
  bookmark: Bookmark,
  asterisk: Asterisk,
  iphone: Iphone,
  "warning-triangle": WarningTriangle,
  "refresh-3": Refresh3,
  "double-chat-bubble": DoubleChatBubble,
  cards: Cards,
  users: Users,
  "color-swatch": ColorSwatch,
  timer: Timer,
  "square-cross": SquareCross,
  minus: Minus,
  "help-circle": HelpCircle,
  photo: Photo,
  "chevron-left": ChevronLeft,
  print: Print,
  "folder-person": FolderPerson,
  "dots-vertical": DotsVertical,
  "chart-increase": ChartIncrease,
  "align-left": AlignLeft,
  "clipboard-check": ClipboardCheck,
  cog: Cog,
  "pencil-ruler": PencilRuler,
};

export type DeprecatedIconType = keyof typeof DEPRECATED_ICONS;
