import { css, StyleDeclaration } from "aphrodite";
import {
  ComponentProps,
  CSSProperties,
  FC,
  forwardRef,
  MouseEvent,
  useCallback,
  useMemo,
} from "react";
import NextLink from "next/link";
import { Route } from "nextjs-routes";
import useHover from "@hooks/useHover";
import { isArray } from "lodash";
import { sharedStyles } from "../styles/sharedStyles";

type Props = ComponentProps<"div"> & {
  direction?: "horizontal" | "vertical";
  width?: "hug" | number | string;
  height?: "hug" | number | string;
  minHeight?: CSSProperties["minHeight"];
  minWidth?: CSSProperties["minWidth"];
  maxWidth?: CSSProperties["maxWidth"];
  maxHeight?: CSSProperties["maxHeight"];
  borderRadius?: CSSProperties["borderRadius"];
  border?: CSSProperties["border"];
  padding?: CSSProperties["padding"];
  paddingHorizontal?: number;
  paddingVertical?: number;
  paddingTop?: CSSProperties["paddingTop"];
  paddingBottom?: CSSProperties["paddingBottom"];
  paddingLeft?: CSSProperties["paddingLeft"];
  paddingRight?: CSSProperties["paddingRight"];
  margin?: CSSProperties["margin"];
  marginHorizontal?: number;
  marginVertical?: number;
  marginTop?: CSSProperties["marginTop"];
  marginBottom?: CSSProperties["marginBottom"];
  marginLeft?: CSSProperties["marginLeft"];
  marginRight?: CSSProperties["marginRight"];
  spaceBetweenItems?: number;
  alignmentVertical?: "top" | "center" | "bottom";
  alignmentHorizontal?: "left" | "center" | "right";
  spacingMode?:
    | "packed"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "end";
  wrap?: CSSProperties["flexWrap"];
  styleDeclaration?: StyleDeclaration | StyleDeclaration[];
  flex?: CSSProperties["flex"];
  alignSelf?: CSSProperties["alignSelf"];
  link?: Route | null | undefined;
  externalUrl?: string;
  linkOpenInNewTab?: boolean;
  hideScrollbar?: boolean;
  hoverStyle?: CSSProperties;
  backgroundColor?: CSSProperties["backgroundColor"];
  "data-html2canvas-ignore"?: boolean;
  "data-testid"?: string;
};

const verticalAlignmentToFlexProperty = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
};
const horizontalAlignmentToFlexProperty = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};
const AutoLayout: FC<Props> = (
  {
    children,
    direction = "horizontal",
    width,
    height,
    minHeight,
    minWidth,
    maxHeight,
    maxWidth,
    borderRadius,
    border,
    styleDeclaration,
    spaceBetweenItems,
    alignmentHorizontal,
    alignmentVertical,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    margin,
    marginHorizontal,
    marginVertical,
    marginBottom,
    marginTop,
    marginLeft,
    marginRight,
    wrap,
    spacingMode,
    style,
    flex,
    alignSelf,
    link,
    externalUrl,
    linkOpenInNewTab,
    hideScrollbar,
    hoverStyle,
    backgroundColor,
    ...props
  },
  ref: React.RefObject<HTMLDivElement>,
) => {
  const justifyContent = useMemo(() => {
    if (spacingMode && direction === "horizontal" && alignmentHorizontal) {
      console.warn(
        "You are using spacingMode as well as horizontalAlignment. horizontalAlignment will be ignored.",
      );
    }
    if (spacingMode && direction === "vertical" && alignmentVertical) {
      console.warn(
        "You are using spacingMode as well as verticalAlignment. verticalAlignment will be ignored.",
      );
    }
    if (spacingMode === "packed") {
      return "flex-start";
    } else if (spacingMode) {
      return spacingMode;
    }

    return direction === "horizontal"
      ? horizontalAlignmentToFlexProperty[alignmentHorizontal || "left"]
      : verticalAlignmentToFlexProperty[alignmentVertical || "top"];
  }, [spacingMode, alignmentHorizontal, alignmentVertical, direction]);
  const [isHovered, hoverBind] = useHover();
  const onMouseEnter = useCallback(
    (e: MouseEvent) => {
      hoverBind.onMouseEnter(e);
      // @ts-ignore
      props.onMouseEnter?.(e);
    },
    [hoverBind, props],
  );
  const onMouseLeave = useCallback(
    (e: MouseEvent) => {
      hoverBind.onMouseLeave(e);
      // @ts-ignore
      props.onMouseLeave?.(e);
    },
    [hoverBind, props],
  );
  const inlineStyles: CSSProperties = useMemo(
    () => ({
      display: "flex",
      flexDirection: direction === "horizontal" ? "row" : "column",
      width: width === "hug" ? "fit-content" : width,
      height: height === "hug" ? "fit-content" : height,
      minHeight,
      minWidth,
      maxHeight,
      maxWidth,
      borderRadius,
      border,
      padding,
      paddingTop: padding || paddingTop || paddingVertical,
      paddingBottom: padding || paddingBottom || paddingVertical,
      paddingLeft: padding || paddingLeft || paddingHorizontal,
      paddingRight: padding || paddingRight || paddingHorizontal,
      margin,
      marginTop: margin || marginTop || marginVertical,
      marginBottom: margin || marginBottom || marginVertical,
      marginLeft: margin || marginLeft || marginHorizontal,
      marginRight: margin || marginRight || marginHorizontal,
      gap: spaceBetweenItems,
      flex,
      alignSelf,
      justifyContent,
      alignItems:
        direction === "horizontal"
          ? verticalAlignmentToFlexProperty[alignmentVertical || "top"]
          : horizontalAlignmentToFlexProperty[alignmentHorizontal || "left"],
      flexWrap: wrap,
      textDecoration: link ? "none" : undefined,
      color: link ? "unset" : undefined,
      backgroundColor,
      ...style,
      ...(isHovered && hoverStyle),
    }),
    [
      alignSelf,
      alignmentHorizontal,
      alignmentVertical,
      direction,
      flex,
      height,
      minHeight,
      minWidth,
      maxHeight,
      maxWidth,
      borderRadius,
      border,
      hoverStyle,
      isHovered,
      justifyContent,
      link,
      margin,
      marginBottom,
      marginHorizontal,
      marginLeft,
      marginRight,
      marginTop,
      marginVertical,
      padding,
      paddingBottom,
      paddingHorizontal,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingVertical,
      spaceBetweenItems,
      style,
      width,
      wrap,
      backgroundColor,
    ],
  );

  const styleDeclarations = useMemo(
    () => (isArray(styleDeclaration) ? styleDeclaration : [styleDeclaration]),
    [styleDeclaration],
  );
  return useMemo(() => {
    if (link || externalUrl) {
      return (
        <NextLink
          // @ts-ignore
          href={externalUrl || link}
          target={linkOpenInNewTab ? "_blank" : undefined}
          rel={linkOpenInNewTab ? "noreferrer" : undefined}
          // @ts-ignore
          ref={ref}
          style={{
            ...inlineStyles,
            textDecoration: "none",
            color: "unset",
          }}
          className={css(
            ...styleDeclarations,
            hideScrollbar && sharedStyles.hideScrollbar,
          )}
          // @ts-ignore
          onMouseEnter={onMouseEnter}
          // @ts-ignore
          onMouseLeave={onMouseLeave}
          {...props}
        >
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          {children}
        </NextLink>
      );
    } else {
      return (
        <div
          data-html2canvas-ignore={props["data-html2canvas-ignore"]}
          ref={ref}
          style={inlineStyles}
          className={css(
            ...styleDeclarations,
            hideScrollbar && sharedStyles.hideScrollbar,
          )}
          {...props}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </div>
      );
    }
  }, [
    link,
    externalUrl,
    linkOpenInNewTab,
    ref,
    inlineStyles,
    hideScrollbar,
    onMouseEnter,
    onMouseLeave,
    props,
    children,
    styleDeclarations,
  ]);
};

// @ts-ignore
export default forwardRef(AutoLayout);
