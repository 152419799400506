import React, { CSSProperties, FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
  style?: CSSProperties;
  className?: string;
  containerClassName?: string;
  visibilityDelayMs?: number;
};

const DelayedSkeleton: FC<Props> = ({
  height,
  width,
  style,
  className,
  containerClassName,
  visibilityDelayMs = 300,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, visibilityDelayMs);
    return () => clearTimeout(timer);
  }, [visibilityDelayMs]);

  if (!isVisible) {
    return null;
  }

  return (
    <Skeleton
      height={height}
      width={width}
      style={style}
      className={className}
      containerClassName={containerClassName}
    />
  );
};

export default DelayedSkeleton;
