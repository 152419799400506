import { StyleSheet } from "aphrodite";
import { deprecatedTones } from "./deprecatedColors";

export const sharedStyles = StyleSheet.create({
  hideScrollbar: {
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },
  inputCorrect: {
    backgroundColor: deprecatedTones.green2,
  },
  inputIncorrect: {
    backgroundColor: deprecatedTones.red3,
  },
  inputNotSelected: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
});
