export const properties = {
  all: "all",
  backgroundColor: "background-color",
  border: "border",
  color: "color",
  boxShadow: "box-shadow",
};

export const durations = {
  S015: "0.15s",
  S025: "0.25s",
  S035: "0.35s",
};

export const curves = {
  easeInOut: "ease-in-out",
  bezier: "cubic-bezier(0.45, 0.25, 0.25, 1)",
};

export const HOVER_TRANSITION = `${properties.all} ${durations.S015} ${curves.bezier}`;
