import { MouseEvent, useMemo, useState } from "react";

export type MouseEvents = {
  onMouseEnter: (e: MouseEvent) => void;
  onMouseLeave: (e: MouseEvent) => void;
};

function useHover(): [boolean, MouseEvents] {
  const [isHovered, setHovered] = useState(false);

  const bind: MouseEvents = useMemo(
    () => ({
      onMouseEnter: () => void setHovered(true),
      onMouseLeave: () => void setHovered(false),
    }),
    [],
  );

  return [isHovered, bind];
}

export default useHover;
